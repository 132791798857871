// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as H3 from "../../../../../styleguide/components/Heading/H3.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_LocationBrochure from "../../../../../routes/common/Routes_LocationBrochure.res.js";
import * as LocationBrochuresIndexSidebarScss from "./LocationBrochuresIndexSidebar.scss";

var css = LocationBrochuresIndexSidebarScss;

function LocationBrochuresIndexSidebar(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Popular Brochures"
                            }),
                        Belt_Array.mapWithIndex(props.popularBrochures, (function (index, brochure) {
                                return JsxRuntime.jsx(A.make, {
                                            href: Routes_LocationBrochure.show(brochure.slug),
                                            className: css.locationBrochureLink,
                                            children: brochure.title
                                          }, String(index));
                              }))
                      ],
                      className: css.popularBrochures
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Recently Added"
                            }),
                        Belt_Array.mapWithIndex(props.recentBrochures, (function (index, brochure) {
                                return JsxRuntime.jsx(A.make, {
                                            href: Routes_LocationBrochure.show(brochure.slug),
                                            className: css.locationBrochureLink,
                                            children: brochure.title
                                          }, String(index));
                              }))
                      ],
                      className: css.recentBrochures
                    })
              ],
              className: css.sidebar
            });
}

var make = LocationBrochuresIndexSidebar;

export {
  css ,
  make ,
}
/* css Not a pure module */
