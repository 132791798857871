// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as HeadingScss from "./Heading.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = HeadingScss;

function H5(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("h5", {
              children: props.children,
              className: Cx.cx([
                    css.h5,
                    className
                  ])
            });
}

var make = H5;

export {
  css ,
  make ,
}
/* css Not a pure module */
